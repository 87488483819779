import { NgClass, NgFor } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { ICurrency } from 'src/app/shared/model/currency.model';
import { VariablesService } from 'src/app/shared/services';
import { CurrencyComponent } from '../currency/currency.component';

@Component({
  selector: 'app-currency-dropdown',
  standalone: true,
  imports: [NgClass, NgFor, MatMenuModule, MatRippleModule, CurrencyComponent],
  templateUrl: './currency-dropdown.component.html',
  styleUrls: ['./currency-dropdown.component.scss']
})
export class CurrencyDropdownComponent implements OnInit {

  @Input() selectedCurrency?: string;
  @Input() currencyList?: ICurrency[];

  @Output() onCurrencyChange = new EventEmitter()

  constructor(
    public vars: VariablesService
  ) {}

  ngOnInit(): void {
    if(!this.selectedCurrency) {
      this.selectedCurrency = this.vars.currency;
    }
  }

  setCurrency(currency?: string) {
    this.selectedCurrency = currency;
    this.onCurrencyChange.emit(currency);
  }
}
