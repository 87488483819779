<div class="wrap">
  <div class="content">
    <div class="left-section">
      <div class="logo">
        <div class="link" routerLink="/">
          <img class="brand-logo" ngSrc="{{vars.domain_details.logoLightBg}}" width="175" height="72" [alt]="vars.domain_details.name" priority />
        </div>
      </div>
    </div>
    <div class="right-section">
      <div class="btns">
        <!-- <button *ngIf="!vars?.pageName?.match('all_fundraiser|stories')" class="go-all-fund" (click)="goToAllFundraiser()" mat-button>Contribute Now</button> -->
      </div>
    </div>
  </div>
</div>
