import { NgClass, NgFor, NgIf, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { Component } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { CurrencyDropdownComponent } from 'src/app/core/currency-dropdown/currency-dropdown.component';
import { IUser } from 'src/app/shared/model/user.model';
import { StorageService, UtilService, VariablesService } from 'src/app/shared/services';
import { ProfileProgressComponent } from "../../../core/profile-progress/profile-progress.component";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgOptimizedImage,
    NgTemplateOutlet,
    NgClass,
    CurrencyDropdownComponent,
    MatIconModule,
    MatRippleModule,
    ProfileProgressComponent
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  constructor(
    public vars: VariablesService,
    private util: UtilService,
    private storageService: StorageService
  ) { }
  userData: IUser | undefined;
  profileSideState = false;
  livesImpactedList = [
    { id: 1, label: 'Donations done', value: '02' },
    { id: 2, label: 'Do good days', value: '22' }
  ]
  menuList = [
    { id: 1, label: 'My Donations', iconName: 'donation', redirectTo: 'mydonations' },
    { id: 2, label: 'FAQ\'s & Support', iconName: 'faq', redirectTo: '' },
    { id: 3, label: 'Download Tax Certificate', iconName: 'download-certificate', redirectTo: '' },
    { id: 4, label: 'Logout', iconName: 'setting', redirectTo: '' },
  ]

  ngOnInit() {
    this.getUserData();
  }

  getUserData() {
    this.vars.userData$.subscribe((val: (IUser | undefined)) => {
      this.userData = val;
    })
  }

  redirectionLogic(item: any) {
    if (this.vars.isBrowser) {
      let a = document.createElement('a');
      // close drawer
      this.profileSideState = false;
      if (item.iconName === 'support') {
        a.href = 'https://customerhappiness.ketto.org/portal/en/kb';
        a.click();
      } else if (item.label === 'Logout') {
        this.logout();
        // removing dock on logout
      } else {
        this.util.router.navigate([item.redirectTo]);
      }
    }
  }

  logout() {
    this.storageService.delete('userdata');
    this.storageService.delete('user');
    this.storageService.deleteCookie('u_auth');
    this.storageService.deleteCookie('k_auth');
    this.util.router.navigate(['/']);
    // this.authService.sessionLogout().subscribe({next: (res) => this.logoutUser(), error: () => this.logoutUser()});
  }


  goToHome() {
    if (this.vars.pageLayoutConfig?.logoNavigate) {
      this.util.router.navigate(['/']);
    }
  }

  onProfileSidebarOpen() {
    this.profileSideState = !this.profileSideState;
    this.vars.sidebarDrawerState.next(this.profileSideState);
    if (this.vars.isBrowser) {
      if (this.profileSideState) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    }
  }

  setCurrency(cur: string) {
    this.util.setCurrency(cur);
  }
}
