<div class="back-holder"></div>
<div class="wrap">
  <div class="content">
    <div class="left-section">
      <div class="logo">
        <div class="link" (click)="goToHome()">
          <img class="brand-logo" ngSrc="{{vars.domain_details.logoLightBg}}" width="175" height="72" [alt]="vars.domain_details.name" priority />
        </div>
      </div>
    </div>
    <div class="right-section">
      <div *ngIf="vars.pageLayoutConfig?.showSecure" class="secure"><img ngSrc="assets/images/lock.png" width="32" height="32" alt="secure"><span>100% secure</span></div>
      <div *ngIf="vars.pageLayoutConfig?.showCurrency" class="currency-drop">
        <app-currency-dropdown (onCurrencyChange)="setCurrency($event)" />
      </div>
      <div *ngIf="vars.isPermanentLoggedIn" class="profile-section">
        <div class="img-wrapper" matRipple (click)="onProfileSidebarOpen()">
          <img *ngIf="userData?.avtar?.cdn_path" [src]="userData?.avtar?.cdn_path" alt="user img" />
          <mat-icon *ngIf="!userData?.avtar?.cdn_path">person</mat-icon>
        </div>
      </div>
      <div class="btns">
        <!-- <button *ngIf="!vars?.pageName?.match('all_fundraiser|stories')" class="go-all-fund" (click)="goToAllFundraiser()" mat-button>Contribute Now</button> -->
      </div>
    </div>
  </div>
</div>

<ng-container *ngTemplateOutlet="profileSideContainer"></ng-container>

<ng-template #profileSideContainer>
  <div class="drawer-sidenav-wrapper" [class]="profileSideState ? 'open-drawer':''">
    <div class="header">
      <mat-icon (click)="onProfileSidebarOpen()">close</mat-icon>
      <div class="text">
        Profile
      </div>
    </div>
    <div class="content-wrapper">
      <div class="profile-completion">
        <div class="profile-progress-wrapper">
          <app-profile-progress />
        </div>
        <div class="profile-data-wrapper">
          <div class="name">{{userData?.full_name}}</div>
          <div class="email">{{userData?.email}}</div>
          <div class="complete-profile-wrapper">
            <a routerLink="/profile">
              <button mat-button class="btn">
                Complete Profile
                <mat-icon>chevron_right</mat-icon>
              </button>
            </a>
          </div>
        </div>
      </div>
      <div class="donation-impact-wrapper">
        <div *ngFor="let item of livesImpactedList" class="impact-card">
          <div class="numeric-value">{{item.value}}</div>
          <div class="impact-label">{{item.label}}</div>
        </div>
      </div>
      <div class="menu-list">
        <a *ngFor="let item of menuList" class="item" (click)="redirectionLogic(item)">
          <div class="menu-icon">
            <img [src]="'/assets/images/header/' + item.iconName + '.svg'" alt="menu icon">
          </div>
          <div class="label">{{item?.label}}</div>
          <div class="icon">
            <mat-icon>chevron_right</mat-icon>
          </div>
        </a>
      </div>
    </div>
  </div>
</ng-template>
