import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { VariablesService } from 'src/app/shared/services';
import { HeaderComponent } from './components/header/header.component';
import { HeaderV2Component } from './components/header-v2/header-v2.component';
import { FooterComponent } from './components/footer/footer.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [NgIf, RouterOutlet, HeaderComponent, HeaderV2Component, FooterComponent],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor(
    public vars: VariablesService
  ) { }
  isSidebarOpened = false;

  ngOnInit(): void {
    this.setSidebarState();
  }

  setSidebarState() {
    this.vars.sidebarDrawerState.subscribe(val => {
      this.isSidebarOpened = val;
    })
  }
}
