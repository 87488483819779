import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ABService, ApiService, EventsService, PaymentService, ScriptLoaderService, SeoService, StorageService, UtilService, VariablesService } from './shared/services';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';

const Services = [
  ABService,
  ApiService,
  EventsService,
  PaymentService,
  ScriptLoaderService,
  SeoService,
  StorageService,
  UtilService,
  VariablesService
];

const sharedModules = [
  MatDialogModule,
  MatSnackBarModule,
  MatSelectModule,
  MatBottomSheetModule
]

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ...sharedModules
  ],
  providers: [
    ...Services
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
