import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { StorageService, UtilService, VariablesService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    public router: Router,
    public storageService: StorageService,
    private util: UtilService,
    private vars: VariablesService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkLogin(state);
  }
  checkLogin(state: RouterStateSnapshot) {
    if (this.vars.isPermanentLoggedIn) {
      return true;
    }
    const data = this.util.getUserData();
    if (data) {
      return true;
    } else {
        // after we add login flow uncomment this
    //   const qPrams = Object.assign({ redirect_uri: state.url.split('?')[0] }, state.root.queryParams);
    //   this.router.navigate(['/signin'], { queryParams: qPrams });
    this.router.navigate(['/']);
      return false;
    }
  }
}
