import { DOCUMENT, NgIf, NgOptimizedImage, NgStyle } from '@angular/common';
import { Component, Inject, Input, Renderer2 } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IUser } from 'src/app/shared/model/user.model';

@Component({
  selector: 'app-profile-progress',
  standalone: true,
  imports: [
    NgIf,
    NgStyle,
    NgOptimizedImage,
    MatIconModule,
  ],
  templateUrl: './profile-progress.component.html',
  styleUrls: ['./profile-progress.component.scss']
})
export class ProfileProgressComponent {
  userData: IUser | undefined;

  dashArray: number = 2 * Math.PI * 60;
  dashOffset: number = 376;
  animationStyle: string = '';
  @Input() percentage: any = 50;

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.setProfileProgressBar();
  }

  setProfileProgressBar( ) {
    const finalOffset = this.dashArray * (1 - this.percentage / 100);

    if (typeof document !== 'undefined') {
      setTimeout(() => {
        this.dashOffset = finalOffset;
        this.addCustomAnimation(finalOffset);
      }, 0);
    }
  }

  
  addCustomAnimation(offset: number) {
    const styleSheet = this.renderer.createElement('style');
    styleSheet.type = 'text/css';
    const keyframes = `
      @keyframes anim {
        100% {
          stroke-dashoffset: ${offset};
        }
      }
    `;
    styleSheet.innerHTML = keyframes;
    this.renderer.appendChild(this.document.head, styleSheet);
  }
}
