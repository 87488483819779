import { NgFor, NgIf, NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { VariablesService } from 'src/app/shared/services';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [NgIf, NgFor, NgOptimizedImage, RouterLink],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  currentYear = new Date().getFullYear();
  socialhandlers = [
    {
      "logopath": "assets/images/social/facebook.svg",
      "clickUrl" : "https://www.facebook.com/equalallorg/"
    },
    {
      "logopath": "assets/images/social/x.svg",
      "clickUrl" : "https://x.com/equalallorg"
    },
    // {
    //   "logopath": "assets/images/social/linkedin.svg",
    //   "clickUrl" : ""
    // },
    // {
    //   "logopath": "assets/images/social/youtube.svg",
    //   "clickUrl" : ""
    // },
    {
      "logopath": "assets/images/social/instagram.svg",
      "clickUrl" : "https://www.instagram.com/equalallorg/"
    },
    // {
    //   "logopath": "assets/images/social/whatsapp.svg",
    //   "clickUrl" : ""
    // },
  ]
  constructor(
    public vars: VariablesService
  ) {}
  linkhandler(link:string){
    window.open(link, "_blank");
  }
}
