export const environment = {
  name: 'staging',
  production: false,
  API_BASE_URL: 'https://dev.equalall.org/api/',
  DOMAIN_URL: 'https://dev.equalall.org',
  bugsnag: 'e9ac81b71cbf1cef3225a069b2fcc3d4',
  clevertap_id: 'KKW-6ZK-4R7Z',
  facebook_id: '287403324698248',
  facebook_pixel: '538031250815425',
  gcaptcha: '6Lemuy0UAAAAAAJL2gRKu1BzpYtg5oI8QJCT89bI',
  google_client_id: '154316787884-v1hbq46oa8g9vpfnt7m7s9b6i3cul0pc.apps.googleusercontent.com',
  google_client_id_one_tap: '1035507078055-g4qo9db9end019j57f1blo0tfr0m0qtm.apps.googleusercontent.com',
  gtm_id: 'G-L6KD7XP7Z2',
  gtm_ids: <any>{
    'equalall': 'G-L6KD7XP7Z2'
  },
  invisible_captcha: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  microsoft_clarity: 'f5d5n1q3au',
  puhser_key: '5a179ea8cd27b83badfc',
  meilisearch: {
    host: 'https://meilisearchdev.ketto.org',
    key: 'c2c6a47901b031f1d6fb89e66a227858ea42ddb3053bb746e32a990cdec751f0',
    index: 'fundraiser_dev',
    disease: {
      index: 'dev_diseases',
      not_listed: 1820
    },
    hospital: {
      index: 'dev_hospitals',
      not_listed: 804783,
      not_admitted: 804784,
      no_hospitalisation_required: 804785
    }
  },
  truecaller: {
    partnerKey: 'wWJP67524561d2a794a8fb52141cdc4f70987',
  },
  health_first_campaign_id: 812634,
  health_first_ct: 'health-first-default-campaign',
  campaign_url: "/stories/test-campaign-equal-all-815730"
};
